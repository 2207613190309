import FeatureDetector from './FeatureDetector.js';

const isIOSDevice = FeatureDetector.isIOSDevice();

class DeviceMonitor {
  constructor() {
    this.tempAudioTrack = null;
    this.tempVideoTrack = null;
    this.audioTrack = null;
    this.videoTrack = null;
    this.audioEnded = null;
    this.videoEnded = null;
    this.boundOnAudioEnd = this.onAudioEnd.bind(this);
    this.boundOnVideoEnd = this.onVideoEnd.bind(this);
    this.listeners = [];
  }

  addAudioTrack(track) {
    if (!isIOSDevice && track && track.readyState !== 'ended') {
      this.tempAudioTrack = track;
    }
  }

  addVideoTrack(track) {
    if (!isIOSDevice && track && track.readyState !== 'ended') {
      this.tempVideoTrack = track;
    }
  }

  applyTempTracks() {
    this.clearTracks();
    if (this.tempAudioTrack) {
      this.audioTrack = this.tempAudioTrack;
      this.tempAudioTrack = null;
      this.audioTrack.addEventListener('ended', this.boundOnAudioEnd);
    }
    if (this.tempVideoTrack) {
      this.videoTrack = this.tempVideoTrack;
      this.tempVideoTrack = null;
      this.videoTrack.addEventListener('ended', this.boundOnVideoEnd);
    }
  }

  discardTempTracks() {
    this.tempAudioTrack = null;
    this.tempVideoTrack = null;
  }

  onAudioEnd() {
    if (this.videoEnded) {
      clearTimeout(this.videoEnded);
      this.emit({ type: 'all_devices_ended' });
      return;
    }
    this.audioEnded = setTimeout(() => {
      if (this.videoEnded) {
        clearTimeout(this.videoEnded);
        this.emit({ type: 'all_devices_ended' });
      } else {
        this.emit({ type: 'audio_device_ended' });
      }
    }, 1000);
  }

  onVideoEnd() {
    this.videoEnded = setTimeout(() => {
      if (!this.audioEnded) {
        this.emit({ type: 'video_device_ended' });
      }
    }, 1000);
  }

  onEvent(callback) {
    this.listeners.push(callback);
  }

  emit(msg) {
    this.listeners.forEach(listener => listener(msg));
  }

  clearTracks() {
    clearTimeout(this.audioEnded);
    clearTimeout(this.videoEnded);
    if (this.audioTrack) {
      this.audioTrack.removeEventListener('ended', this.boundOnAudioEnd);
      this.audioTrack = null;
    }
    if (this.videoTrack) {
      this.videoTrack.removeEventListener('ended', this.boundOnVideoEnd);
      this.videoTrack = null;
    }
    this.audioEnded = null;
    this.videoEnded = null;
  }

  destroy() {
    this.listeners.length = 0;
    this.discardTempTracks();
    this.clearTracks();
  }
}

export default DeviceMonitor;

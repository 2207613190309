/*eslint no-console: 0*/

/**
 * eyeson Logger implement debug|info|warn|error methods.
 **/
export default (function () {
  return {
    error: function (...args) {
      return console.error(...args);
    },
    warn: function (...args) {
      return console.warn(...args);
    },
    info: function (...args) {
      return console.log(...args);
    },
    debug: function (...args) {
      if (!console.debug) {
        return null;
      }
      return console.debug(...args);
    }
  };
})();

import screenfull from 'screenfull';

class FullscreenHelper {
  constructor() {
    this.onChange = this.onChange.bind(this);
    this.hasSupport = Boolean(screenfull.isEnabled);
  }

  toggle() {
    return this.isFullscreen() ? screenfull.exit() : screenfull.request();
  }

  canFullscreen() {
    return this.hasSupport;
  }

  isFullscreen() {
    return this.hasSupport && screenfull.isFullscreen;
  }

  onChange(callback) {
    if (!this.hasSupport) {
      return;
    }

    screenfull.on('change', () => callback(this.isFullscreen()));
  }

  off() {
    if (!this.hasSupport) {
      return;
    }

    screenfull.off('change');
  }
}

export default FullscreenHelper;

import FeatureDetector from './FeatureDetector.js';
import Logger from './Logger.js';

/**
 * Wrapper around localStorage API in order to prefix keys to be stored and
 * offer default values on demand. If no localStorage is provided by
 * the user-agent, we use a temporary fallbackStorage.
 **/
export default (function () {
  const prefix = 'eyeson.';
  const fallbackStorage = {};
  const hasLocalStorage = FeatureDetector.hasLocalStorage();

  return {
    store: function (key, value) {
      if (!hasLocalStorage) {
        fallbackStorage[key] = value;
        return true;
      }
      try {
        window.localStorage.setItem(prefix + key, JSON.stringify(value));
      } catch (error) {
        Logger.error('LocalStorage::store', error);
        return false;
      }
      return true;
    },
    // eslint-disable-next-line max-statements
    load: function (key, defaultValueParam) {
      let defaultValue = defaultValueParam;
      if (typeof defaultValue === 'object' && !Array.isArray(defaultValue)) {
        defaultValue = Object.assign({}, defaultValue);
      }
      if (!hasLocalStorage) {
        return fallbackStorage[key] || defaultValue;
      }
      let storedValue = window.localStorage.getItem(prefix + key);
      if (!storedValue) {
        return defaultValue;
      }
      try {
        return JSON.parse(storedValue);
      } catch (error) {
        Logger.error('LocalStorage::load', key, error);
        return defaultValue;
      }
    }
  };
})();

// https://github.com/YuzuJS/setImmediate/blob/modern-browsers-only/setImmediate.js
const immediate = (() => {
  const stack = [];
  const target = '*';
  const messageName = 'com.eyeson.immediate';
  const handleMessage = event => {
    if (event.source === window && event.data === messageName) {
      event.stopPropagation();
      const fn = stack.shift();
      if (fn) {
        fn();
      }
    }
  };
  window.addEventListener('message', handleMessage);
  return fn => {
    stack.push(fn);
    window.postMessage(messageName, target);
  };
})();

export default immediate;
